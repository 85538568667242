import axios from 'axios';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import ValidationResult from './ValidationResult';
import PaymentPopup from './PaymentPopup';
import RemainingCodesPopup from './RemainingCodesPopup';
import InfoPopup from './InfoPopup';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ValidationPage = () => {
  const [originZip, setOriginZip] = useState('');
  const [destinationZip, setDestinationZip] = useState('');
  const [purchaseCode, setPurchaseCode] = useState(''); // New state for the purchase code
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [isValidationPopupOpen, setIsValidationPopupOpen] = useState(false);
  const [loading, setLoading] = useState({ rate: false, oneLookup: false, tenLookups: false });
  const [rateResult, setRateResult] = useState(null);
  const [isRemainingCodesPopupOpen, setIsRemainingCodesPopupOpen] = useState(false);
  const [isPaymentOptionshow, setIsPaymentOptionshow] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);
    if (code) {
      setPurchaseCode(code); // Automatically set the purchase code if passed in the query string
    }
  }, []);

  useEffect(() => {
    const { device } = queryString.parse(window.location.search);
    if (device === 'iphone') {
      setIsPaymentOptionshow(false);
    }
  }, []);

  const handleGetRate = (e) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, rate: true }));
    axios.post(`${BASE_URL}/api/lookup/get-zip-details`, { originZip, destinationZip, code: purchaseCode })
      .then((response) => {
        setLoading((prev) => ({ ...prev, rate: false }));
        setRateResult(response.data.data);
        setIsValidationPopupOpen(true);
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, rate: false }));
        console.error('Rate fetch error:', error);
        setRateResult(null);
        alert(error.response?.data?.message || 'An error occurred while fetching the rate.');
      });
  };

  const openRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(true);
  };

  const closeRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(false);
  };

  const openPaymentPopup = () => {
    if (isPaymentOptionshow) {
      setIsPaymentPopupOpen(true);
    }
    else {
      handleOpenPopup();
    }
  };

  const closePaymentPopup = () => {
    setIsPaymentPopupOpen(false);
  };

  const closeValidationPopup = () => {
    setIsValidationPopupOpen(false);
  };

  const purchaseLookups = (endpoint, plan) => {
    setLoading((prev) => ({ ...prev, [plan]: true }));
    axios.get(`${BASE_URL}/api/payment/${endpoint}`)
      .then((response) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        if (response.data) {
          console.log('Payment success:', response.data);
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
          }
        }
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        console.error('Payment error:', error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white relative">
      <header className="w-full bg-[#114e91] text-white py-4">
        <nav className="container mx-auto flex justify-end items-center px-4 flex-wrap gap-4">
          <div className="flex space-x-4">
            <p onClick={openPaymentPopup} className="text-white cursor-pointer">Pricing</p>
            <a href="/contact" className="text-white">Contact Us</a>
            <a href="/about" className="text-white">About Us</a>
          </div>
          <button onClick={openRemainingCodesPopup} className="bg-white text-[#114e91] px-4 py-2 rounded-lg mt-2 sm:mt-0">
            Search for Remaining Codes
          </button>
          <button onClick={openPaymentPopup} className="bg-white text-[#114e91] px-4 py-2 rounded-lg mt-2 sm:mt-0">
            Buy Credits
          </button>
        </nav>
      </header>
      <InfoPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />

      {isRemainingCodesPopupOpen && (
        <RemainingCodesPopup onClose={closeRemainingCodesPopup} />
      )}

      <div className="w-full flex flex-col items-center mt-8">
        <h1 className="text-3xl font-bold text-[#114e91] mb-4">FastFreightRate.com</h1>
        <img className="mb-4 object-contain w-full max-w-md"
        src="/white-logo.jpg" alt="logo" />
      
      </div>

      <div className='flex flex-col items-center w-full p-5 mb-5 gap-5 mt-10'>
        <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-full max-w-md border border-[#114e91] md:w-full w-[90%]">
          <form onSubmit={handleGetRate} className="w-full">
            <div className="mb-4">
              <label htmlFor="originZip" className="block text-sm font-bold text-black-700">
                Origin ZIP Code
              </label>
              <input
                type="text"
                id="originZip"
                value={originZip}
                onChange={(e) => setOriginZip(e.target.value)}
                className="mt-2 p-2 w-full border rounded shadow-sm focus:ring-[#114e91] focus:border-[#114e91]"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="destinationZip" className="block text-sm font-bold text-black-700">
                Destination ZIP Code
              </label>
              <input
                type="text"
                id="destinationZip"
                value={destinationZip}
                onChange={(e) => setDestinationZip(e.target.value)}
                className="mt-2 p-2 w-full border rounded shadow-sm focus:ring-[#114e91] focus:border-[#114e91]"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="purchaseCode" className="block text-sm font-bold text-black-700">
                Enter Code
              </label>
              <input
                type="text"
                id="purchaseCode"
                value={purchaseCode}
                onChange={(e) => setPurchaseCode(e.target.value)}
                className="mt-2 p-2 w-full border rounded shadow-sm focus:ring-[#114e91] focus:border-[#114e91]"
                required
              />
            </div>
            {!purchaseCode && (
              <div className="text-sm mb-6">
                Don't have a purchase code yet? <button type="button" className="text-[#114e91]" onClick={openPaymentPopup}>Purchase now</button>
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-[#114e91] text-white py-2 rounded-lg transition duration-200"
              disabled={loading.rate}
            >
              {loading.rate ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Get Rate'
              )}
            </button>
          </form>
        </div>

        <div className="mt-4 max-w-4xl mx-auto text-gray-700 text-left px-4 space-y-4 text-center">
          <h2 className="text-2xl font-semibold text-[#fe1e39]">Welcome to FastFreightRate.com</h2>
          <p className="font-bold">Get Instant Freight Rates with No Subscription Required!</p>
          <p>
            At FastFreightRate.com, we offer a hassle-free solution for obtaining ground freight rates across various vehicle types, including sprinter vans, straight trucks, and dry-van tractor trailers. Our platform is designed to provide you with accurate and up-to-date freight rates that reflect the current market conditions.
          </p>
          {/* <h3 className="text-xl font-semibold text-[#114e91]">Why Choose FastFreightRate.com?</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li><span className="font-semibold">Accurate and Reliable:</span> Our rates are calculated using the patent-pending rating system from Collaborate.us, which was first developed in 1997. Over the years, this system has been perfected to find the most accurate average pricing in the industry. By factoring in elements like current capacity, fuel prices, and historical data, we ensure you receive the most precise rate quotes available.</li>
            <li><span className="font-semibold">Flexible Options:</span> Whether you need a single rate quote or a bundle of quotes, we have you covered. Our platform allows you to purchase just what you need, without any long-term commitments or hidden fees.</li>
            <li><span className="font-semibold">Ad Hoc Pricing:</span> Unlike most similar platforms that require a subscription, FastFreightRate.com offers ad hoc pricing. This means you only pay for the rate quotes you need, when you need them—no recurring payments, no contracts.</li>
          </ul>
          <h3 className="text-xl font-semibold text-[#114e91]">How It Works</h3>
          <ol className="list-decimal pl-5 space-y-2">
            <li><span className="font-semibold">Purchase with Ease:</span> Whether you need just one rate or multiple, our platform allows you to purchase quotes individually or in bundles, giving you the flexibility to choose what works best for you.</li>
            <li><span className="font-semibold">Enter Your Details:</span> Simply provide the origin and destination ZIP codes.</li>
            <li><span className="font-semibold">Enter Your Purchase Code:</span> Enter the purchase code you obtained during the checkout process.</li>
            <li><span className="font-semibold">Receive Your Rate:</span> Instantly receive an accurate freight rate based on real-time data using our perfected rating system.</li>
          </ol>
          <h3 className="text-xl font-semibold text-[#114e91]">Why We're Different</h3>
          <p>
            FastFreightRate.com is designed with your convenience in mind. We understand that the freight industry can be unpredictable, and you need a reliable tool that adapts to your needs. That's why we've created a platform that eliminates the need for subscriptions and offers rates that truly reflect the current market landscape. Backed by over two decades of refinement, our rating system ensures that you get the most accurate pricing available.
          </p>
          <h3 className="text-xl font-semibold text-[#114e91]">Get Started Today</h3>
          <p>
            Experience the ease and efficiency of FastFreightRate.com for yourself. Whether you're a small business or a large logistics company, our platform provides the reliable freight rate quotes you need—when you need them.
          </p>
          <p className="font-bold">
            Visit www.fastfreightrate.com to get started!
          </p> */}
        </div>

        <img className="h-[10rem] mt-8" src="/white-logo.jpg" alt="Terms of Use" />
        {isPaymentOptionshow && (
        <div id="pricing" className="mt-8 w-full max-w-md bg-gray-100 p-6 rounded-lg shadow-md text-center border border-gray-300">
          <h2 className="text-xl font-bold mb-4 text-[#114e91]">Pricing</h2>
          <ul className="text-gray-700 text-lg">
            <li className="mb-2">1 Look Up: <span className="font-semibold">$2.99</span></li>
              <li className="mb-2">10 Look Ups: <span className="font-semibold">$7.99</span></li>
              <li className="mb-2">25 Look Ups: <span className="font-semibold">$14.99</span></li>
              <li className="mb-2">50 Look Ups: <span className="font-semibold">$29.99</span></li>
              <li className="mb-2">100 Look Ups: <span className="font-semibold">$54.99</span></li>
              <li className="mb-2">1000 Look Ups: <span className="font-semibold">$299.99</span></li>
          </ul>
          <button className="bg-[#114e91] text-white px-4 py-2 rounded-lg mt-4" onClick={openPaymentPopup}>
            Buy Credits
          </button>
        </div>
        )}
      </div>

      {isPaymentPopupOpen && (
        <PaymentPopup
          closePaymentPopup={closePaymentPopup}
          purchaseLookups={purchaseLookups}
          loading={loading}
        />
      )}

      {isValidationPopupOpen && (
        <ValidationResult
          validationResult={rateResult}
          closeValidationPopup={closeValidationPopup}
        />
      )}

      <div className="mt-auto py-5 w-full bg-gray-200 text-center">
        <a href="/PrivacyNotice.pdf" target='_blank' className="text-sm text-[#114e91] mx-2">Privacy Policy</a>
        <a href="/CollaboRateTermsofServiceUpdated8-24.pdf" target='_blank' className="text-sm text-[#114e91] mx-2">Terms and Conditions</a>
      </div>
    </div>
  );
};

export default ValidationPage;
