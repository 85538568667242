import axios from 'axios';
import React, { useState } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const RemainingCodesPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted
    
    axios.post(`${BASE_URL}/api/lookup/find-code-with-email`, { email }).then((response) => {

    // Simulate API call
    setTimeout(() => {
      setIsSubmitted(true); // Set submission state to true after response
      setIsLoading(false); // Turn off loading indicator
    }, 1000);
}).catch((error) => {
    console.error('Error:', error);
    setIsLoading(false); // Turn off loading indicator
    })
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-4 relative">
        <button
          className="absolute top-4 right-4 text-black text-2xl focus:outline-none"
          onClick={() => {
            onClose();
            setIsSubmitted(false); // Reset submission state
            setIsLoading(false); // Reset loading state
          }}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold text-center mb-4">Search Remaining Codes</h2>
        {isSubmitted ? (
          <div className="text-center text-green-600">
            <p>Your request has been sent successfully.</p>
            <p>Check your email for details about your remaining codes.</p>
            <button
              className="mt-4 bg-[#114e91] text-white py-2 px-4 rounded hover:bg-[#3a6cd4] focus:outline-none transition duration-200"
              onClick={() => {
                onClose();
                setIsSubmitted(false); // Reset submission state
                setIsLoading(false); // Reset loading state
              }}
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <p className="text-gray-600 text-center mb-6">
              Enter your email below to receive details about your remaining code credits.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#114e91] focus:border-[#114e91]"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#3a6cd4] focus:outline-none focus:ring-2 focus:ring-[#114e91] focus:ring-offset-2 transition duration-200"
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                ) : (
                  'Send Codes'
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default RemainingCodesPopup;
