import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>
      <div className="mt-4 max-w-4xl mx-auto text-gray-700 text-left px-4 space-y-4">
          {/* <h2 className="text-2xl font-semibold text-[#114e91]">Welcome to FastFreightRate.com</h2>
          <p className="font-bold">Get Instant Freight Rates with No Subscription Required!</p>
          <p>
            At FastFreightRate.com, we offer a hassle-free solution for obtaining ground freight rates across various vehicle types, including sprinter vans, straight trucks, and dry-van tractor trailers. Our platform is designed to provide you with accurate and up-to-date freight rates that reflect the current market conditions.
          </p> */}
          <h3 className="text-xl font-semibold text-[#114e91]">Why Choose FastFreightRate.com?</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li><span className="font-semibold">Accurate and Reliable:</span> Our rates are calculated using the patent-pending rating system from Collaborate.us, which was first developed in 1997. Over the years, this system has been perfected to find the most accurate average pricing in the industry. By factoring in elements like current capacity, fuel prices, and historical data, we ensure you receive the most precise rate quotes available.</li>
            <li><span className="font-semibold">Flexible Options:</span> Whether you need a single rate quote or a bundle of quotes, we have you covered. Our platform allows you to purchase just what you need, without any long-term commitments or hidden fees.</li>
            <li><span className="font-semibold">Ad Hoc Pricing:</span> Unlike most similar platforms that require a subscription, FastFreightRate.com offers ad hoc pricing. This means you only pay for the rate quotes you need, when you need them—no recurring payments, no contracts.</li>
          </ul>
          <h3 className="text-xl font-semibold text-[#114e91]">How It Works</h3>
          <ol className="list-decimal pl-5 space-y-2">
            <li><span className="font-semibold">Purchase with Ease:</span> Whether you need just one rate or multiple, our platform allows you to purchase quotes individually or in bundles, giving you the flexibility to choose what works best for you.</li>
            <li><span className="font-semibold">Enter Your Details:</span> Simply provide the origin and destination ZIP codes.</li>
            <li><span className="font-semibold">Enter Your Purchase Code:</span> Enter the purchase code you obtained during the checkout process.</li>
            <li><span className="font-semibold">Receive Your Rate:</span> Instantly receive an accurate freight rate based on real-time data using our perfected rating system.</li>
          </ol>
          <h3 className="text-xl font-semibold text-[#114e91]">Why We're Different</h3>
          <p>
            FastFreightRate.com is designed with your convenience in mind. We understand that the freight industry can be unpredictable, and you need a reliable tool that adapts to your needs. That's why we've created a platform that eliminates the need for subscriptions and offers rates that truly reflect the current market landscape. Backed by over two decades of refinement, our rating system ensures that you get the most accurate pricing available.
          </p>
          <h3 className="text-xl font-semibold text-[#114e91]">Get Started Today</h3>
          <p>
            Experience the ease and efficiency of FastFreightRate.com for yourself. Whether you're a small business or a large logistics company, our platform provides the reliable freight rate quotes you need—when you need them.
          </p>
          <p className="font-bold">
            Visit www.fastfreightrate.com to get started!
          </p>
        </div>

    </div>
  );
};

export default AboutUs;
