import React, { useEffect, useRef } from 'react';

const ValidationResult = ({ validationResult: data, closeValidationPopup }) => {
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closeValidationPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='fixed inset-0 flex items-start justify-center z-50 bg-black bg-opacity-50 overflow-y-auto'>
      <div ref={popupRef} className='bg-white max-w-5xl w-full m-4 p-6 rounded-lg shadow-lg overflow-y-auto'>
        <button
          onClick={closeValidationPopup}
          className="text-red-500 font-semibold mb-4 absolute top-4 right-4"
        >
          &times; Close
        </button>
        {data.map((quote, index) => (
          <div key={quote.quoteId} className="flex flex-col md:flex-row border-b-2 py-6 mb-6">
            {/* Truck Image and Info */}
            <div className="md:w-1/2 flex items-center md:flex-row flex-col">
              <img
                src={`/images/${quote.name.toLowerCase().replace(/\s+/g, '-')}.jpg`} // Assuming images are named accordingly
                alt={quote.name}
                className="h-48 w-64 object-contain"
              />
              <div className="ml-4">
                <h3 className="text-2xl font-bold">{quote.name}</h3>
                <div className="text-gray-600 mt-2">
                  <p><strong>Truck Info:</strong></p>
                  <ul className="list-disc pl-5">
                    <li>Rate: ${quote.rate} per mile</li>
                    {/* <li>Minimum Rate: ${quote.minRate}</li> */}
                    <li>Base Total Before: ${quote.baseTotalBefore.toFixed(2)}</li>
                    <li>Fuel Surcharge: ${quote.gasSurcharge}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Quote Info and Actions */}
            <div className="md:w-1/2 mt-6 md:mt-0 md:ml-8">
              <h4 className="text-xl font-semibold">
                Quote <span className="text-blue-600">#{quote.quoteId}</span>
              </h4>
              <p className="text-gray-800 mt-2">
                {quote.transitTimeEst > 24 ? `Up to ${quote.transitTimeEst}h from time of pickup` : `Approximately ${quote.transitTimeEst}h from time of pickup`}
              </p>
              {/* <p className="text-sm text-blue-600 underline mt-1 cursor-pointer">Weather & Road Conditions</p> */}
              <p className="mt-2"><strong>Total Miles:</strong> {quote.miles} miles</p>
              <p><strong>Total Charges:</strong> ${quote.total.toFixed(2)}</p>
              {/* <p className="text-blue-600 underline mt-2 cursor-pointer">Show Rate Breakdown</p> */}

              {/* <div className="mt-4 flex space-x-4">
                <button className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-200">
                  Accept Quote
                </button>
                <button className="bg-orange-600 text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-200">
                  Counter Quote
                </button>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ValidationResult;
